body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

div.avatar-icon {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .rbc-event {
  background-color: #a333c8 !important;
} */

.dimmer {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.horizontal-grid-segment {
  margin: 0 !important;
  background-color: white;
}

/* Commenting out money class until fixing the label on an input of classname money. The text align should only apply to the input field */
.money-column
/* .money  */
 {
  text-align: right;
}

.checkbox-column,
.centered-column {
  text-align: center;
}

#background-image {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1000;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}
#landing > div.ui.basic.padded.segment > div > div > div > div.rbc-time-view {
  background-color: white;
}

.rbc-toolbar .rbc-btn-group,
.rbc-toolbar + div {
  background-color: white;
}

.required {
  color: red;
}

.navbar {
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3) !important;
  min-height: 3.5em !important;
}

@media only screen and (min-width: 768px) {
  #homepage {
    padding-top: 4.25em !important;
  }
}

.responsive-header {
  margin: 0px !important;
}
.responsive-header .item > .button {
  margin: 0px !important;
}

@media print {
  .no-print {
    visibility: hidden;
  }
}

.background {
  background-color: lavender;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -5;
  margin-top: -5.5vh;
}

.ui.button {
  background: #dadada none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
}
.ui.button:hover {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.tiny-delete {
  box-shadow: none !important;
}
.ui.segments > .horizontal.segments {
  background-color: white;
}

.background.login {
  margin-top: 0px !important;
}
.centered {
  text-align: center !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.primary-row {
  background-color: hsl(120, 50%, 80%);
}
.negative-row {
  background-color: hsl(360, 50%, 80%);
}

.selected-row {
  border-top: purple 1px dashed;
  border-bottom: purple 1px dashed;
  background-color: rgba(128, 0, 128, 0.15) !important;
  margin: -1px 0px;
}
.dividing-row {
  border-bottom: black 1px solid;
  margin: 0px 0px -1px 0px;
}

.appointment-status-conflicting {
  background-color: rgba(252, 0, 0, 0.2) !important;
}
.appointment-status-confirmed {
  background-color: rgba(0, 128, 21, 0.15) !important;
}
.appointment-status-cancelled {
  background-color: rgba(252, 0, 0, 0.15) !important;
}

.hover-button {
  position: fixed !important;
  bottom: 10px !important;
  left: 10px !important;
  z-index: 50 !important;
}

.important-column {
  font-weight: bold;
}

.ui.attached.tabular.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

h2.ui.header {
  -webkit-user-select: text !important;
  user-select: text !important;
}

/*********************************** Filepond file uploader overrides ********************/
.filepond--file-action-button {
  cursor: pointer;
}

.filepond--root {
  margin: auto auto;
}

.appointment-uploader .filepond--item {
  cursor: pointer;
}

@media (min-width: 30em) {
  .appointment-uploader .filepond--item {
    width: calc(25% - 0.25em);
  }
}

@media (min-width: 50em) {
  .appointment-uploader .filepond--item {
    width: calc(33.33% - 0.3em);
  }
}
/* Make navbar in front of everything */
.navbar {
  z-index: 1000 !important;
}

/************************************ Canvas Styling ************************************/
.signature-canvas {
  border: 1px solid lightgray;
  height: 150px;
  width: 100%;
  border-radius: 4px;
}

/************************************ Slider Styling ************************************/
.slider {
  -webkit-appearance: none;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-style: none;
  border-radius: 50%;
  background: purple;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-style: none;
  border-radius: 50%;
  background: purple;
  cursor: pointer;
}

/************ Removing some of the opacity of disabled buttons ***************/
.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  opacity: 0.7 !important;
}

/*********************************** Other *************************************/
.ReactTable
  > div.rt-table
  > div.rt-thead.-filters
  > div
  > div.rt-th.dropdown-filter {
  overflow: visible;
}
.ReactTable > .rt-table > .rt-thead.-filters > div > div > input[type="text"] {
  height: 100%;
}

#navbar-extension {
  max-width: 120px;
}

.ui.form .disabled.field {
  pointer-events: none;
  opacity: 1;
}

