.background {
  background-color: lavender;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -5;
  margin-top: -5.5vh;
}

.ui.button {
  background: #dadada none;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
}
.ui.button:hover {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.tiny-delete {
  box-shadow: none !important;
}
.ui.segments > .horizontal.segments {
  background-color: white;
}

.background.login {
  margin-top: 0px !important;
}
.centered {
  text-align: center !important;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary-row {
  background-color: hsl(120, 50%, 80%);
}
.negative-row {
  background-color: hsl(360, 50%, 80%);
}

.selected-row {
  border-top: purple 1px dashed;
  border-bottom: purple 1px dashed;
  background-color: rgba(128, 0, 128, 0.15) !important;
  margin: -1px 0px;
}
.dividing-row {
  border-bottom: black 1px solid;
  margin: 0px 0px -1px 0px;
}

.appointment-status-conflicting {
  background-color: rgba(252, 0, 0, 0.2) !important;
}
.appointment-status-confirmed {
  background-color: rgba(0, 128, 21, 0.15) !important;
}
.appointment-status-cancelled {
  background-color: rgba(252, 0, 0, 0.15) !important;
}

.hover-button {
  position: fixed !important;
  bottom: 10px !important;
  left: 10px !important;
  z-index: 50 !important;
}

.important-column {
  font-weight: bold;
}

.ui.attached.tabular.menu {
  overflow-x: auto;
  overflow-y: hidden;
}

h2.ui.header {
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

/*********************************** Filepond file uploader overrides ********************/
.filepond--file-action-button {
  cursor: pointer;
}

.filepond--root {
  margin: auto auto;
}

.appointment-uploader .filepond--item {
  cursor: pointer;
}

@media (min-width: 30em) {
  .appointment-uploader .filepond--item {
    width: calc(25% - 0.25em);
  }
}

@media (min-width: 50em) {
  .appointment-uploader .filepond--item {
    width: calc(33.33% - 0.3em);
  }
}
/* Make navbar in front of everything */
.navbar {
  z-index: 1000 !important;
}

/************************************ Canvas Styling ************************************/
.signature-canvas {
  border: 1px solid lightgray;
  height: 150px;
  width: 100%;
  border-radius: 4px;
}

/************************************ Slider Styling ************************************/
.slider {
  -webkit-appearance: none;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-style: none;
  border-radius: 50%;
  background: purple;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-style: none;
  border-radius: 50%;
  background: purple;
  cursor: pointer;
}

/************ Removing some of the opacity of disabled buttons ***************/
.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  opacity: 0.7 !important;
}

/*********************************** Other *************************************/
.ReactTable
  > div.rt-table
  > div.rt-thead.-filters
  > div
  > div.rt-th.dropdown-filter {
  overflow: visible;
}
.ReactTable > .rt-table > .rt-thead.-filters > div > div > input[type="text"] {
  height: 100%;
}

#navbar-extension {
  max-width: 120px;
}

.ui.form .disabled.field {
  pointer-events: none;
  opacity: 1;
}
