body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

div.avatar-icon {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .rbc-event {
  background-color: #a333c8 !important;
} */

.dimmer {
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.horizontal-grid-segment {
  margin: 0 !important;
  background-color: white;
}

/* Commenting out money class until fixing the label on an input of classname money. The text align should only apply to the input field */
.money-column
/* .money  */
 {
  text-align: right;
}

.checkbox-column,
.centered-column {
  text-align: center;
}

#background-image {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1000;
  filter: alpha(opacity=50); /* For IE8 and earlier */
}
#landing > div.ui.basic.padded.segment > div > div > div > div.rbc-time-view {
  background-color: white;
}

.rbc-toolbar .rbc-btn-group,
.rbc-toolbar + div {
  background-color: white;
}

.required {
  color: red;
}

.navbar {
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.3) !important;
  min-height: 3.5em !important;
}

@media only screen and (min-width: 768px) {
  #homepage {
    padding-top: 4.25em !important;
  }
}

.responsive-header {
  margin: 0px !important;
}
.responsive-header .item > .button {
  margin: 0px !important;
}

@media print {
  .no-print {
    visibility: hidden;
  }
}
